import styled, { css } from 'styled-components';
import rem from 'polished/lib/helpers/rem';
// components
import Button from '../../../../components/Button';
import { Col } from '../../../../foundations/Grid';

export const ButtonStyled = styled(Button)`
  ${({ theme }) => css`
    && {
      min-width: unset;
      min-height: unset;
      padding: ${rem(32)} 0;
      color: ${theme.colors.neutral.grayDark20};
      font-size: ${rem(14)};
      line-height: ${rem(22)};
      text-align: left;
      :hover {
        color: ${theme.colors.action.lightTheme.linkHover};
        text-decoration: underline;
        text-underline-offset: ${rem(5)};
      }
    }
  `}
`;

export const ColStyled = styled(Col)`
  && {
    #ot-sdk-btn.ot-sdk-show-settings.nmx-override {
      padding: ${rem(32)} 0; /* additional settings to override stock OneTrust button styles for Sare footer */
    }
  }
`;
